
// Live environment
export const publicUrl = 'https://surfi.ch:3000';
export const apiUrl = 'https://surfi.ch:3000/api';

// Test environment
// export const publicUrl = 'https://surfi-test.switzerlandnorth.cloudapp.azure.com:3000';
// export const apiUrl = 'https://surfi-test.switzerlandnorth.cloudapp.azure.com:3000/api';

// localhost
// export const publicUrl = 'http://localhost:3000';
// export const apiUrl = 'http://localhost:3000/api';
